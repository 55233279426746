<template>
  <nav class="navbar navbar-expand-lg">
   <div class="container-fluid">
     <router-link class="nav-link active" aria-current="page" to="/"> <img src="../assets/cosechame-Photoroom.png" alt="logoCosechame" class="navbar-logo"></router-link>
     <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
       <span class="navbar-toggler-icon"></span>
     </button>
     <div class="collapse navbar-collapse" id="navbarSupportedContent">
       <ul class="navbar-nav me-auto mb-2 mb-lg-0">
         <li class="nav-item">
           <router-link class="nav-link active" aria-current="page" to="/">Home</router-link>
         </li>
         <li class="nav-item">
           <router-link class="nav-link active" aria-current="page" to="/orchard">Huerta Comercial</router-link>
         </li>
         <li class="nav-item">
           <router-link class="nav-link active" aria-current="page" to="/countryside">Vida de Campo</router-link>
         </li>
         <li class="nav-item">
           <router-link class="nav-link active" aria-current="page" to="/innovation">Innovación</router-link>
         </li>
         
       </ul>
     
     </div>
   </div>
 </nav>
 </template>
 
 <style scoped>
 .navbar {
   display: flex;
   align-items: center;
   padding: 10px 20px;
   background-color: #FF9800;
 }
 
 .navbar-logo {
   height: 100px; /* Ajusta la altura de la imagen */
   margin-right: 20px; /* Espacio entre la imagen y el resto del contenido de la navbar */
 }
 
 .navbar-nav .nav-link {
   color: #2C7865; /* Color del texto */
   text-decoration: none;
   padding: 0 10px;
 }
 
 .navbar-nav .nav-link:hover {
   color: #D9EDBF; /* Color del texto al pasar el cursor */
 }
 </style>
 