<template>
  <footer class="footer">
    <div class="footer__contact-info">
      <img src="../assets/cosechame-Photoroom.png" alt="logoCosechame">
      <p class="footer__text">contacto.cosechame@gmail.com</p>
      <p class="footer__text">+569 4582 4887</p>
    </div>
    <div class="footer__social-links">
      <a
        href="https://www.facebook.com/profile.php?id=100063700064934"
        target="_blank"
        class="footer__social-link footer__social-link--facebook"
      >
        <i class="fa-brands fa-facebook"></i>
      </a>
      <a
        href="https://wa.me/56945824887/?text=Hola,%20quiero%20conocer%20los%20productos🌱"
        target="_blank"
        class="footer__social-link footer__social-link--whatsapp"
      >
      <i class="fa-brands fa-whatsapp"></i>
      </a>
      <a
        href="https://www.instagram.com/cosechame/"
        target="_blank"
        class="footer__social-link footer__social-link--instagram"
      >
        <i class="fa-brands fa-instagram"></i>
      </a>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>

<style scoped>
.footer {
  background-color: #FF9800;
  padding: 20px 0;
  text-align: center;
}
.footer img {
  height: 150px; /* Ajusta la altura de la imagen */
  margin-right: 10px; /* Espacio entre la imagen y el resto del contenido de la navbar */
}

.footer__contact-info {
  margin-bottom: 10px;
}

.footer__text {
  font-size: 16px;
  margin: 0;
  color: #2C7865;
}


.footer__social-links {
  margin-top: 10px;
}

.footer__social-link {
  margin-right: 15px;
  font-size: 24px;
  text-decoration: none;
}

.footer__social-link--facebook {
  color: #3b5998;
}

.footer__social-link--whatsapp {
  color: #1a5e15;
}

.footer__social-link--instagram {
  color: #c32aa3;
}

</style>