<template>
  <div class="landing-page">
    <!-- Hero Section -->
    <section class="hero d-flex align-items-center justify-content-center">
      <div class="hero-text">
        <h1>Agricultura Familiar</h1>
        <p>
          Integrando nuestro conocimiento para una vida más sostenible y
          significativa.
        </p>
      </div>
    </section>

    <!-- Main Content Section -->
    <section class="content container py-5">
      <div class="row">
        <div class="col-md-4 col-sm-6 mb-4">
          <div class="content-item">
            <img src="../assets/img/home/vidacampo.jpg" class="img-fluid" alt="Vida de Campo">
            <h2>Vida de Campo</h2>
            <p>Conectando con la esencia de la vida rural</p>
            <router-link aria-current="page" to="/countryside">
              <button class="btn">Continuar</button>
            </router-link>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 mb-4">
          <div class="content-item">
            <img src="../assets/img/home/20221013_115434_08.jpg" class="img-fluid" alt="Huerta Comercial">
            <h2>Huerta Comercial</h2>
            <p>Maximizando el potencial agrícola con prácticas sostenibles</p>
            <router-link aria-current="page" to="/orchard">
              <button class="btn">Continuar</button>
            </router-link>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 mb-4">
          <div class="content-item">
            <img src="../assets/img/home/20220215_184205_05.jpg" class="img-fluid" alt="Innovación">
            <h2>Innovación</h2>
            <p>Innovando para un futuro más verde</p>
            <router-link aria-current="page" to="/innovation">
              <button class="btn">Continuar</button>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  components: {},
};
</script>

<style scoped>
.hero {
  background-image: url('../assets/img/home/20211225_110610_mediana.jpg');
  background-size: cover;
  background-position: center;
  color: white;
  height: 80vh;
  position: relative;
}

.hero div {
  position: absolute;
  top: 20px;
  right: 20px;
  text-align: right;
}

.hero h1 {
  font-size: 6vw; /* Responsivo basado en el ancho de la ventana */
  margin-bottom: 20px;
}

.hero p {
  font-size: 2vw; /* Responsivo basado en el ancho de la ventana */
  max-width: 600px;
  margin-left: 200px;
  text-shadow: -1px -1px 1px #000, 1px 1px 1px #000, -1px 1px 1px #000, 1px -1px 1px #000;
  -webkit-text-fill-color: #ffffff;
  -webkit-text-stroke: 0.2px black;
}

@media (max-width: 768px) {
  .hero h1 {
    font-size: 8vw; /* Se ajusta en pantallas más pequeñas */
  }

  .hero p {
    font-size: 4vw; /* Se ajusta en pantallas más pequeñas */
    margin-left: 50px; /* Reduce el margen para pantallas pequeñas */
  }
}

.content-item {
  text-align: center;
}

.content-item img {
  width: 80%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px;
  opacity: 0.8;
  transition: opacity 0.3s ease, width 0.5s ease;
}

.content-item img:hover {
  opacity: 1;
  width: 90%;
  height: auto;
}

.content-item h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.content-item p {
  font-size: 16px;
  margin-bottom: 20px;
}

button {
  background-color: #ff9800;
  color: #d9edbf;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #90d26d;
  color: #d9edbf;
}
</style>
