<template>
  <div class="app">
    <NavbarComponent />
    <router-view />
    <FooterComponent />
    <a class="contact-me"
       href="https://wa.me/56945824887/?text=Hola,%20quiero%20conocer%20los%20productos🌱"
       target="_blank">
       <img src="./assets/img/icons/whatsapp-2.svg" alt="icono de whatsapp">
    </a>
  </div>

</template>

<script>
import NavbarComponent from "./components/NavbarComponent.vue";
import FooterComponent from "./components/FooterComponent.vue"

export default {
  name: "App",
  components: { NavbarComponent, FooterComponent },
};
</script>

<style>
.app {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

.contact-me {
  width: clamp(70px, 10vh, 90px);
  position: fixed;
  bottom: 10vh;
  right: 10vh;

}
/* El color del ícono se puede cambiar en
   src/assets/icons/whatsapp.svg cambiando
   el atributo fill de la segunda línea */
.contact-me img {
  width: 100%;
}
</style>